:root {
    --light_gray: #E0E0E0;
    --dark_gray: #D9D9D9;
    --b-dark: #424A54;
    --dark: #292929;
    --color_subtitle: #545454;
    --bs-brand_medium_green: #126e36;
    --bs-primary-shade-20: #23ce6b !important;
    --bs-brand_green: #23ce6b;
    --bs-brand_light_green: #d8ffea;
    --bs-border-color: #eaecf0;
    --bs-dark-grey: #667085;
    --bs-body-color: #1a202c;
    
}

body {
    font-family: "Inter", sans-serif !important;
    color: var(--bs-body-color);
    font-size: 16px;
    overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6,p{
    font-family: "Inter", sans-serif !important;
}

.bg-soft-primary {
    background-color: #EBF3FC !important;
}

.bg-soft-danger {
    background-color: #FDF6F6 !important;
    color: #F04438 !important;
}

.bg-soft-success {
    background-color: #E6FFE6 !important;
    color: #12B76A !important;
}

.bg_soft_brown {
    background-color: #E5E6D6;
}

.dropdown-item {
    color: #616161;
}

.light_blue_color {
    color: var(--b-light-blue);
}

.green_lbl_border_btn,
.red_lbl_border_btn {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    display: inline-block;
}

.green_lbl_border_btn {
    background-color: #F1FAF1;
    border: 1px solid #9FD89F;
    color: #0E700E;

}

.red_lbl_border_btn {
    background-color: #FDF6F6;
    border: 1px solid #F1BBBC;
    color: #BC2F32;
}


thead,
tbody,
tfoot,
tr,
td,
th {
    white-space: inherit !important;
}

.h3 {
    font-size: 24px;
}

h4,
.h4 {
    font-size: 20px;
    margin: 0 0 3px 0;
    color: var(--b-dark);

}

.h5 {
    font-size: 18px;
}

h6,
.h6 {
    font-size: 16px;
    margin: 0 0 3px 0;
    color: var(--b-dark);

}

.lead {
    font-size: 14px;
    margin: 0 0 3px 0;
    color: var(--bs-dark-grey);
    font-weight: 400;
}

.font-weight-500 {
    /* font-family: 'Segoe UI 500'; */
    font-weight: 600;
}
.text_grey_color{
    color: var(--bs-dark-grey);
}
/* for pointer cursor style */
.pointer_cur {
    cursor: pointer;
}

/* comman */
.link_text {
    color: var(--bs-primary) !important;
}
.link_text a:hover{
    color: var(--bs-brand_medium_green);
}
.link_underline {
    color: #101828 !important;
    text-decoration: underline;
}

.link_underline:hover {
    color: #126E36 !important;
    cursor: pointer;
}

.subtitle_color {
    color: var(--color_subtitle);
}

.highlight_td_text {
    background-color: #FDF6F6;
    color: #BC2F32;
    border: 1px solid #F1BBBC;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 3px;
}
.hr_border_color
{
    /* border-color: #EAECF0; */
    border-color:#acb0b7;
}
/* font-weight */
.fw_500
{
    font-weight: 500;
}
.fw_600
{
    font-weight: 600;
}
/*  */
.switch_ltr.form-switch {
    padding-left: 0;
}

.switch_ltr.form-switch .form-check-input {
    margin-left: 10px;
}

.comman_btn {
    background-color: var(--bs-primary);
    color: var(--bs-white);
    padding: 7px 16px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1px;
}

.comman_btn:focus {
    opacity: .9;
}

.comman_btn.btn:disabled,
.comman_btn.btn.disabled,
.bordered_btn.btn-primary:disabled {
    background-color: #F0F0F0 !important;
    border: 1px solid #b4bfb8 !important;
    color: #BDBDBD;
    padding: 7px 16px;
    font-size: 14px;
    opacity: 1;
}

.comman_btn .btn_lft_icon {
    margin-right: 5px;
}
.comman_btn.btn-primary:hover,
.comman_btn.btn-primary:focus,
.comman_btn:hover
 {
    color: var(--bs-white);
    background-color: var(--bs-brand_medium_green);
    border-color: var(--bs-brand_medium_green);
}
.comman_btn:focus
{
    background-color: var(--bs-primary);
    color: var(--bs-white);
}
.bordered_btn {
    color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    font-size: 14px;
    padding: 7px 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
}

.bordered_btn.ml-16 {
    margin-left: 16px;
}

.bordered_btn .btn_lft_icon,
.btn_link .btn_lft_icon {
    margin-right: 5px;
}

.bordered_btn:hover,
.bordered_btn:focus,
.bordered_btn.btn-primary.btn:hover {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
    background-color: var(--bs-brand_light_green) !important;
}

.btn_link {
    color: var(--bs-primary);
    padding: 0;
    border: none;
    font-weight: 500;
    font-size: 14px;
    background-color: transparent;
}

.btn_link:hover,
.btn_link:focus {
    color: var(--bs-primary);
}

.btn_link.btn.disabled {
    color: var(--dark_gray);
    background-color: transparent !important;
}

.btn_link.btn.disabled svg path {
    fill: var(--dark_gray);
}

/*  */
.btn.btn-primary:focus,
.btn.btn-primary:hover {
    background-color: var(--bs-brand_medium_green) !important;
    border-color: var(--bs-brand_medium_green) !important;
}
/* checkbox custom style*/
.checkbox_custom .form-check-input
{
    width: 20px;
    height: 20px;
    border: 2px solid #e2e8f0;
    margin-top: 2px;
}
.checkbox_custom .form-check-input:active,
.checkbox_custom .form-check-input:checked
{
    background-color: var(--bs-brand_light_green);
    filter: brightness(100%);
    border-color: var(--bs-brand_green) !important;
    background-image: url('../images/checkmark_active.png');
}
.checkbox_custom.checkbox_small .form-check-input
{
    width: 16px;
    height: 16px;
}
.card_header {
    /* border-bottom: 1px solid #D9D9D9 !important; */
}

.card .card-header {
     padding: 16px 16px 0 16px;
}

.soft_red {
    background-color: #FDF6F6;
    color: #BC2F32;
    border: 1px solid #F1BBBC;
}

.soft_red:hover,
.soft_red:focus {
    background-color: #fae9e9;
    color: #BC2F32;
    border: 1px solid #F1BBBC;
}

.soft_green {
    background-color: #F1FAF1;
    color: #0E700E;
    border: 1px solid #9FD89F;
}

.soft_green:hover,
.soft_green:focus {
    background-color: #daf7da;
    border: 1px solid #9FD89F;
    color: #0E700E;
}

.soft_yellow {
    background-color: #fffef5;
    color: #817400;
    border: 1px solid #fef7b2;
}

.soft_yellow:hover {
    background-color: #fbf9eb;
    color: #817400;
    border: 1px solid #fef7b2;
}

.soft_blue {
    background-color: #EBF3FC;
    color: #19297F;
    border: 1px solid #B4D6FA;
}

.soft_blue:hover,
.soft_blue:focus {
    background-color: #dfebf8;
    color: #19297F;
    border: 1px solid #B4D6FA;
}

.soft_gray {
    background-color: #F0F0F0;
    color: #616161;
    border: 1px solid #E0E0E0;
}

.soft_gray:hover,
.soft_gray:focus {
    background-color: #e8e8e8;
    color: #616161;
    border: 1px solid #E0E0E0;
}

.soft_black {
    background-color: #777777;
    color: var(--bs-white);
    border: 1px solid #eeeeee;
}

.soft_black:hover,
.soft_black:focus {
    background-color: #757373;
    color: var(--bs-white);
    border: 1px solid #eeeeee;
}
.page_title_box{
    margin-bottom: 20px;
}
.page_title {
    font-size: 20px;
    color: #1d2939;
    font-weight: 600;
    line-height: 1.5;
    font-family: "Inter", sans-serif !important;
    margin: 0;
}
.page_title_sub_head
{
    font-size: 18px;
    color: #1d2939;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}
.page_sub_title {
    color: #616161;
    font-size: 14px;
    font-weight: 400;
}

.two_column_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

/*  */
.modal_footer_custom {
    border-top: 1px solid #E0E0E0;
    padding: 10px 0 0 0;
}

/* scrollbar_comman */
.scrollbar_style::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    height: 6px;
}

.scrollbar_style::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #808080;
}

.scrollbar_style::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

/* Track */
.scrollbar_style::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.scrollbar_style::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d0d5dd;

}

/* Handle on hover */
.scrollbar_style ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* == form style == */
.p_relative {
    position: relative;
}

.form_width_comman {
    width: 100%;
}
.sup_manatory
{
    color: #f04438;
    font-size: 13px;
    top: -2px;
    left: 2px;
    font-weight: 500;
}
.form_style_comman .form-group {
    margin-bottom: 17px;
}

.form_style_comman label,
.audit_sheet_edit_modal label {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--bs-dark-grey);
    font-weight: 500;
}

.form_style_comman .two_column_title p {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--bs-dark-grey);
    font-weight: 500;
}

.form-control {
    -webkit-appearance: revert;
    -moz-appearance: revert;
    appearance: revert;
    resize: none;
    color: #1a202c;
    font-weight: 400;
}

.form-control:focus {
    color: #1a202c;
    border-color: var(--bs-primary);
}

.form_style_comman .form-control:focus {
    border-color: var(--bs-primary);
}

.form_style_comman .css-13cymwt-control:hover {
    border-color: var(--bs-primary);
}

.form_style_comman .css-t3ipsp-control {
    border-color: var(--bs-primary);
}

.form_style_comman .css-t3ipsp-control:hover {
    border-color: var(--bs-primary);
}

.form_style_comman.was-validated .form-control:valid {
    border-color: #E0E0E0 !important;
    background: transparent;
}

.was-validated .form-control:valid {
    background: none;
}

.form_style_comman.was-validated .form-select:valid {
    border-color: #E0E0E0 !important;
    background: transparent;
}

.form_style_comman.was-validated .form-control.is-valid {
    border-color: #E0E0E0 !important;
    background: transparent;
}

.form_style_comman.was-validated .form-control:invalid,
.form-control.is-invalid {
    background: transparent;
}

.form_style_comman.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #000;
}

.form_style_comman.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: rgba(0, 0, 0, 0.25);
}

.form_style_comman.was-validated .form-control:invalid,
.form-control.is-invalid {
    padding-right: 1rem;
}

.form_style_comman .form-select {
    border-color: #E0E0E0;
    color: #000;
    padding-right: 14px !important;
}

.form_style_comman .form-select:focus {
    border-color: var(--bs-primary);
}


.was-validated .form-control:valid
{
    border-color: #d0d5dd !important;
}
.was-validated .form-control:valid:focus
{
    border-color: var(--bs-primary) !important;
}
.form_style_comman .form-select:focus {
    border-color: var(--bs-primary);
}

.form_width_comman .css-1fdsijx-ValueContainer {
    min-height: 52px;
}

.form_width_comman .css-1dimb5e-singleValue {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: inherit;
}

.form_width_comman .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.form_width_comman .hg-prm-control.form-control {
    height: auto;
    min-height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: inherit;
    resize: vertical;
}

.form_style_comman::placeholder {
    color: #98a2b3;
}

.form-control::placeholder {
    color: #98a2b3;
    font-size: 14px;
    letter-spacing: 0.4px;
}

/* nav sec */
.nav_sec {
    /* -webkit-box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.07); */
    border-bottom: 1px solid var(--bs-border-color);
}

.header_search {
    position: absolute;
    left: 240px;
    border-right: none !important;
}

.nav .header_search .search-input {
    width: 220px;
}

.header_nav ul .nav-item {
    margin-left: 0px;
    margin-bottom: 0;
}

.head_user_desig {
    margin-left: 15px;
}

.head_user_desig h5 {
    font-size: 14px;
    color: #656B74;
    margin: 0 0 0px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    white-space: nowrap;
    min-width: 100px;
    max-width: 130px;
}

.head_user_desig p {
    font-size: 10px;
    color: #656B74;
    margin: 0 0 0px 0;
}

.user_head {
    background-color: #D9D9D9;
    height: 30px;
    width: 30px;
}

.head_user_caret {
    margin-left: 15px;
    font-size: 16px;
}

.nav_sec .dropdown-divider {
    border-top-color: #e4e9ec;
}

/* sidebar style */
.sidebar-base .nav-item .nav-link:not(.disabled) .icon {
    width: 35px;
    height: 35px;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0 auto;
}

.sidebar-base .nav-item .nav-link span.menu_new_tag{
    background-color: #000;
    color: var(--bs-brand_green) !important;
    padding: 0px 3px;
    position: absolute !important;
    top: -7px;
    right: 0px;
    border: 1px solid var(--bs-brand_green);
    border-radius: 4px;
    width: 32px !important;
    min-width: 32px !important;
    left: auto !important;
    font-size: 10px;
}

.analytics_dashboard_sec 
{
    margin-right: -13px;
    margin-left: -23px;
}
.anlytics_iframe
{
    min-height: calc(100vh - 161px);
    
}
.analytics_sidebar_only.sidebar_right_nav{
    width: 12%;
}
.analytics_contentbar_only.content_wrap_main
{
    width: 88%;
}
.content_wrap_main.analytics_fullwidth
{
    width: 100%;
}
.analytics_sidebar_only span.text_ellipses
{
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    display: inline-block;
}
.analytics_sidebar_only .sidebar-right p
{
    font-size: 14px;
    padding: 0 15px 8px 15px;
    display: flex;
}

.sidebar-base .nav-item .nav-link .icon img{
    width: 16px;
}
.sidebar-base .nav-item .nav-link.active .icon {
    position: relative;
}

.sidebar-base .nav-item .nav-link.active .icon::before {
    content: "";
    border-left: 3px solid #9363f0;
    width: 3px;
    position: absolute;
    height: 100%;
    left: -9px;
    display: none;
}

.sidebar-base .nav-item .nav-link {
    /* background-color: rgba(182, 184, 189, .8); */
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 0px 10px 8px 10px !important;
    
}
.sidebar-base .nav-item .nav-link .item-name
{
    font-weight: 500;
    line-height: 1.3;
    font-size: 10px !important;
    letter-spacing: 0.3px;
}

.sidebar-base .nav-item .nav-link.active,
.sidebar-base .nav-item .nav-link.active:hover {
    color: var(--bs-white) !important;
    background-color: var(--bs-brand_green);
}

.sidebar-base .nav-item .nav-link.active .icon svg {
    color: var(--bs-white);
}

.sidebar-base .nav-item:not(.static-item) {
    padding-left: 0;
}

.sidebar-base .nav-item .nav-link {
    /* background-color: rgba(182, 184, 189, .8); */
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 0px 10px 8px 10px !important;
}

.sidebar-base .nav-item .nav-link.active,
.sidebar-base .nav-item .nav-link.active:hover {
    color: #101828 !important;
    /* background-color: var(--bs-brand_light_green); */
}
.sidebar-base .nav-item .nav-link.active:hover .item-name
{
    color: #101828 !important;
}

.sidebar-base .nav-item .nav-link:hover {
    color: #fff !important;
    transition-duration: 0.1s;
    /* background-color: rgba(255, 255, 255, 0.2); */
    background-color: #232a39;
}
.sidebar-base .nav-item .nav-link:hover .item-name
{
    color: #fff !important;
}
.sidebar.left-bordered .sidebar-body:not(.sub-nav) .nav-item:not(.static-item)::before {
    display: none;
}

/*======  page Layout style ====*/
.main-content .content-inner {
    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.sidebar_right_nav {
    padding-left: 0;
    padding-right: 0;
    width: 20%;
}

.content_wrap_main {
    width: 80%;
    /* padding-bottom: 30px; */
}

.content_wrap_comman {
    padding-top: 20px;
    padding-bottom: 30px;
    min-height: calc(70vh - 55px);
    padding-left: 8px;
    padding-right: 8px;
}

.content_wrap_comman .btn-field {
    position: relative;
    z-index: 1;
}

.sidebar-right {
    background-color: #f7fafc;
    padding: 15px 0px;
    min-height: calc(100vh - 68px);
    position: sticky;
    top: 67px;
    border-right: 1px solid #DCDEFF;
}

.sidebar-right p {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 0 15px 14px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.sidebar-right .page_nav {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-right .page_nav li {
    margin-bottom: 6px;
}

.sidebar-right .page_nav li a {
    padding-left: 20px;
    position: relative;
    color: #424A54;
    display: block;
    padding: 8px 15px !important;
    font-size: 14px;
    font-weight: 500;
}

.sidebar-right .page_nav li a:hover {
    color: var(--bs-primary);
}

.sidebar-right .page_nav li a.active {
    color: var(--bs-primary);
    background-color: var(--bs-brand_light_green);
}

.sidebar-right .page_nav li a:before {
    content: "\f111";
    font-family: 'FontAwesome';
    font-size: 8px;
    position: absolute;
    left: 0;
    top: 4px;
}

/* === breadcumb custamisation ==== */
.breadcrumb_box .breadcrumb
{
    margin: 0 0 16px 0;
}
.breadcrumb_box .breadcrumb-item+.breadcrumb-item::before {
    content: "/";
    font-family: 'FontAwesome';
    padding-right: 9px;
    color: #0E1434;
    font-size: 14px;
    font-weight: 600;
    top: -1px;
    position: relative;
}

.breadcrumb_box .breadcrumb-item {
    margin-right: 3px;
    font-size: 12px;
    font-weight: 500;
}

.breadcrumb_box .breadcrumb-item a {
    color: var(--bs-dark-grey) !important;
}

.breadcrumb_box .breadcrumb-item:first-child {
    top: -2px;
    position: relative;
    display: none;
}
.breadcrumb_box .breadcrumb-item:nth-child(2)
{
    padding-left: 0;
}
.breadcrumb_box .breadcrumb-item:nth-child(2):before {
    display: none;
    
}

.breadcrumb_box .breadcrumb-item.active {
    color: #475467 !important;
}
/* main page body */
.main_page_body
{
    /* margin-top: 20px; */
}
/* modal style */
.modal-header
{
    border-bottom: none;
}
.modal_header h4,
.modal-header h4
{
    font-size: 18px;
    color: var(--bs-body-color);
    margin: 2px 0 0 0;
    font-weight: 600;
}
/* card style */
.card {
    border: 1px solid var(--bs-border-color);
    width: 100%;
    box-shadow: none;
}

.card-header .card-title {
    font-family: var(--bs-black);
}

/*  */
.form-control {
    border: 1px solid var(--bs-border-color);
    height: 39px;
}
.form-select
{
    height: 39px;
}
.form-control:disabled {
    background-color: #e9ecef !important;
    color: #BDBDBD !important;
}

.form-label {
    color: #11193F;
}

.form-switch .form-check-input {
    width: 40px;
    height: 20px;
}

/*====== Tree Views Custamisations =========== */
.tree-node--expanded .arrow.arrow--open {
    transform: rotate(90deg);
}

.tree li {
    font-size: 13px;
    color: #19297F;
    margin-bottom: 4px;
}

.tree .tree-node-group {
    padding-left: 15px;
}

.tree li .tree-node--expanded {
    font-weight: 500;
}

.tree-node .form-check-input {
    fill: #fff;
    border-radius: 2px;
    border-color: #D1D1D1;
}

.tree-node--selected .form-check-input {
    fill: var(--bs-primary);

}

.treeview_style .tree-node {
    background-color: var(--bs-white);
    padding: 7px 10px;
    margin: 0 0 4px 0;
    border-radius: 5px;
    transition: all 0.5s ease-in-out 0s;
    cursor: pointer;
    font-size: 12px;
}

.treeview_style .tree-node:hover {
    background-color: #E9EBF4;
    color: var(--bs-primary);
}

.treeview_style .tree-node svg {
    font-size: 20px;
}

.treeview_style .tree .tree-node-group .tree-leaf-list-item {
    list-style: none;
}

.treeview_style .tree-node-group {
    padding-left: 0 !important;
}

.treeview_style .tree-leaf-list-item--focused {}

.treeview_style .sub_tag {
    float: right;
}

.node.focused i {
    position: relative;
}

.node.focused i::before {
    content: "";
    position: absolute;
    width: 1000px;
    height: 100%;
    background-color: #f4f4f4 !important;
    left: -10px;
    height: calc(100% + 22px);
    top: -11px;
    border-radius: 3px;

}

.drpdown_tree_select .tag-item:not(:first-child) input::placeholder {
    color: transparent !important;
}

.drpdown_tree_select .tag-item:not(:first-child) {
    display: none;
}

.tat_action_list {
    display: flex;
    justify-content: space-between;
    color: #11193F;
}

.tat_action_list .tat_action span {
    font-size: 12px;
}

.tat_action_list .tat_action a {
    margin-left: 10px;
}

.tat_action_list .tat_action img {
    width: 18px;
}

/* checkbox color change style */
.drpdown_tree_select input[type="checkbox"].checkbox-item {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #e2e8f0;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  /* Style the checkbox when checked */
  .drpdown_tree_select input[type="checkbox"].checkbox-item:checked {
    background-color: var(--bs-brand_light_green);
    border-color: var(--bs-brand_green);
  }

  /* Add a custom check mark */
  .drpdown_tree_select input[type="checkbox"].checkbox-item:checked::after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: solid #46be1c;
    border-width: 0 1.5px 1.5px 0;
    margin-top: 2px;
    margin-left: 5px;
    transform: rotate(45deg);
  }

  /* Style the label text */
  .drpdown_tree_select input[type="checkbox"].checkbox-item + span.node-label {
    margin-left: 4px;
  }
/* ================= Table Style Custamisations ============ */
.table_scroll_height {
    min-height: 300px;
    overflow-y: auto;
    max-height: 300px;
    padding-right: 10px;
}

.td_img_box {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}

.table_style_comman .table {
    border: 1px solid #eaecf0;
}

.table_style_comman .table td,
.table_style_comman .table thead th {
    padding: 10px 8px;
    color: var(--bs-dark-grey);
    font-size: 13px;
    vertical-align: middle;
    border-color: #edf2f7;
}
.table_style_comman .table thead th 
{
    background-color: #f9fafb;
    font-weight: 500;
    font-size: 12px;
    color: #4a5568 !important;
    border-color: #edf2f7 !important;
}
.table_style_comman .table thead th strong{
    font-weight: 500;
}
/* .table_style_comman .table thead th {
    
    font-weight: 500;
    padding-top: 12px;
    padding-bottom: 12px;
    
} */

.table_style_comman .table td .form-control {
    font-size: 14px;
}

.agent_tbl_scorewise .was-validated .form-control:valid,
.form-control.is-valid {
    background: none;
}

.table_style_comman .table td .form-control::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 400;
}

.table_style_comman .table td .form-control::-moz-placeholder {
    font-size: 14px;
    font-weight: 400;
}

.table_style_comman .table td .form-control:-ms-input-placeholder {
    font-size: 14px;
    font-weight: 400;
}

.table_style_comman .table td .form-control:-moz-placeholder {
    font-size: 14px;
    font-weight: 400;
}

.table_style_comman.pills-rebuttal-tab1 .table td:last-child i {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 348px;
}

.table_style_comman .table td span {
    /* display: inline-flex; */
    /* max-width: 185px; */
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* text-wrap: nowrap; */
}

.table_style_comman .table.table-striped tr:nth-child(odd) td {
    background-color: #fff !important;
    box-shadow: none !important;
}

.table_style_comman .table-striped>tbody>tr:nth-of-type(even)>*,
.table_style_comman .table.table-striped tr:nth-child(even) td {
    background-color: #F9F9F9 !important;
    --bs-table-accent-bg: #F9F9F9 !important;
    color: #484964;
}

.table_style_comman .table.table-striped .td_error {
    background-color: #F9F9F9 !important;
    border-color: #BC2F32;
}

/* for tr error */
.table_style_comman .table-striped>tbody>tr.td_error:nth-of-type(even)>*,
.table_style_comman .table.table-striped tr.td_error:nth-child(even) td,
.table_style_comman .table-striped>tbody>tr.td_error:nth-of-type(odd)>*,
.table_style_comman .table.table-striped tr.td_error:nth-child(odd) td {
    background-color: #FDF6F6 !important;
    --bs-table-accent-bg: #FDF6F6 !important;
    border-top: 1px solid #BC2F32;
    color: #BC2F32;
}

.table_style_comman .dataTables_info {
    font-size: 14px;
    color: #323136;
    padding-top: 0 !important;
}

.dataTables_info .paginate_button {
    display: inline-block;
    border: 1px solid #dee2e6;
    margin: 0 3px;
    padding: 3px 15px;
    border-radius: 2px;
}

.td_input_edit {
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
    color: var(--bs-danger);
}

.table_chat_audit .td_parameter_width {
    min-width: 250px;
    word-break: break-word;
}

.table_chat_audit .td_status_width {
    min-width: 193px;
}

.td_tooltip_warp .td_tooltip {
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    top: -24px;
    min-width: 120px;
    display: none;
}

.td_tooltip_warp .td_tooltip:before {
    content: "\f0d7";
    font-family: 'FontAwesome';
    position: absolute;
    bottom: -12px;
    color: #000;
}

.td_tooltip_warp:hover .td_tooltip {
    display: block;
}

/* form-style */
.password_tab_body {
    max-width: 100%;
    width: 100%;
}

.hide_password_eye {

    input::-ms-reveal,
    input::-ms-clear {
        display: none !important;
    }
}

.change_password_box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 15px !important;
}

.change_password_box li {
    display: flex;
    align-items: flex-start !important;
    margin: 0 0 10px 0 !important;
    flex: 0 0 auto;
    width: 50%;
    font-size: 14px;
}

.change_password_box li span {
    top: -5px;
    position: relative;
    padding-left: 7px;
}

/* .prm_body{ max-width:300px; max } */

.btn_right {
    display: flex;
    justify-content: flex-end;
}

/* Access Management page style */

.access_mngt_btn_area {
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.access_mngt_btn_top_only .page-heading-box {
    margin-bottom: 5px;
}

.access_mngt_btn_area .add_m_user_btn {
    margin-right: 10px;
    color: var(--bs-primary);
    font-size: 14px;
    font-weight: 600;
}

.access_mngt_btn_area .add_user_btn {
    background-color: var(--bs-primary);
    color: #FFF;

}

/* multi-select */
.react-dropdown-tree-select .dropdown .dropdown-trigger,
.react-dropdown-tree-select .dropdown,
.react-dropdown-tree-select .dropdown .dropdown-content {
    width: 100%;
    z-index: 5;
}
.diy_desig
{
    background-color: #fff !important;
    z-index: 6 !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border-color: var(--bs-border-color) !important;
    border-radius: 3px;
    background-color: #fff;
    padding-left: 10px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger:focus {
    border-color: #19297F !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    padding: 12px 16px !important;
    border-radius: 4px;
    border-top: none !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .085) !important;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 320px;

}

.react-dropdown-tree-select .dropdown .dropdown-content::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    height: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.react-dropdown-tree-select .dropdown .dropdown-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 10px;
    height: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #808080;
}

.react-dropdown-tree-select .dropdown .dropdown-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.react-dropdown-tree-select .dropdown .dropdown-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.react-dropdown-tree-select .dropdown .dropdown-content::-webkit-scrollbar-thumb {
    background: #d0d5dd;

}

/* Handle on hover */
.react-dropdown-tree-select .dropdown .dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.react-dropdown-tree-select .tag-item {
    width: 93%;
    margin: 0px;
    display: flex;
    align-items: center;
}

.react-dropdown-tree-select .tag-item .search {
    border: none;
    font-size: 14px;
    
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after,
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    position: absolute;
    right: 10px;
    top: 10px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled .search {
    background: none;
}

.react-dropdown-tree-select .tag-list {
    display: flex;
}

.react-dropdown-tree-select .toggle.collapsed:after,
.react-dropdown-tree-select .toggle.expanded:after {
    font-family: 'FontAwesome';
    font-style: normal;
    z-index: 1;
}

.react-dropdown-tree-select .toggle.collapsed:after {
    content: "\f105";
}

.react-dropdown-tree-select .toggle.expanded:after {
    content: "\f107";
}
.drpdown_tree_select.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after
{
    content: "\f107" !important;
    font-family: 'FontAwesome';
    font-size: 18px;
}
.drpdown_tree_select.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after{
    content: "\f107" !important;
    font-family: 'FontAwesome';
    font-size: 18px;
}

.dropdown-content ul li {
    /* background-color: #F2F2F2; */
    margin: 0 0 5px 0;
    padding: 8px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.drpdown_tree_select .dropdown-content label {
    z-index: 1;
    cursor: pointer;
}

.drpdown_tree_select .node.focused {
    background: transparent !important;
}

.dropdown-content ul li .toggle {
    margin-left: 14px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.radio-select [type="radio"] {
    display: none
}
/* obs horizontal scorall start */
.obs_horizontal_scrollbar .drpdown_tree_select .infinite-scroll-component 
{
    overflow: inherit !important;
}
.obs_horizontal_scrollbar .drpdown_tree_select .dropdown-content ul li
{
    overflow: inherit !important;
}
.obs_horizontal_scrollbar .drpdown_tree_select.react-dropdown-tree-select .dropdown .dropdown-content
{
    overflow-x: auto;
}
/* obs horizontal scorall end */
/*  */
.parameter_view {
    background-color: #F4F4F9;
    padding-right: 5px;
}

.parameter_form_view .content_wrap_comman {
    padding-right: 15px;
}

.dashed_border {
    border: 1px dashed #ccc;
    padding: 8px 8px;
    border-radius: 3px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.dashed_border label {
    margin-bottom: 0;
}

.no_rt_padd .form-check-input {
    margin-top: 0;
}

.tat_inp_small {
    max-width: 70px;
}

.total_weightage .total_weightage_bx {
    min-width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.total_weightage .total_weightage_bx.dashed_border {
    background-color: #ECF2FF;
    border: 1px dashed #A0AEC0;
    min-width: 90px;
    font-size: 12px;
    color: #000;
}

.total_weightage .total_weightage_bx strong {
    color: var(--bs-primary);
    font-size: 16px;
}

.total_weightage p {
    margin: 0;
    font-size: 12px;
}

.caption_txt {
    font-size: 10px;
    color: var(--color_subtitle);
}
.label_color{
    color: var(--bs-dark-grey);
}

/*  */
.parameter_card {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #EEEEF2;
    margin-bottom: 20px;
}

.parameter_card .parameter_card_head {
    border-bottom: 1px solid #EEEEF2;
}

.parameter_card .parameter_card_head .h6 {
    width: 200px;
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 16px;
    flex: 1;
}

.parameter_card .parameter_card_head,
.parameter_card .parameter_card_body {
    padding: 15px 15px;
}

.parameter_view_header .sub_parameter_box,
.parameter_view_header .sub_parameter_box:hover {
    border: none;
    background-color: transparent;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.parameter_list_wrap {
    margin-top: 10px;
}

.parameter_box {
    max-width: 349px;
    width: 100%;
    margin: 0 auto;
}

.sub_parameter_box {
    display: flex;
    background-color: var(--bs-white);
    padding: 10px;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    transition: all 0.5s ease-in-out 0s;
    margin-left: 20px;
}

.sub_parameter_right .para_comman {
    flex: 0 0 auto;
}

.sub_parameter_right .para_row {align-items: center;}

.sub_parameter_right .move {
    width: 10%;
}

.sub_parameter_right .para_name {
    width: 36%;
}

.sub_parameter_right .para_weightage {
    width: 12%;
}

.sub_parameter_right .para_fatal {
    width: 12%;
}

.sub_parameter_right .para_remark {
    width: 15%;
}

.sub_parameter_right .para_reason {
    width: 15%;
}

.sub_parameter_right .para_action {
    width: 8%;
}

.parameter_list_wrap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.parameter_list_wrap ul li {
    position: relative;

}

.parameter_list_wrap ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 20px;
    width: 14px;
    height: 14px;
    background: #D9D9D9;
    border-radius: 50%;
}

.parameter_list_wrap ul li::after {
    content: "";
    width: 2px;
    height: 102%;
    background: #D9D9D9;
    display: flex;
    position: absolute;
    top: 34px;
    left: 6px;
}

.parameter_list_wrap ul li:last-child::after {
    display: none;
}

.sub_parameter_box.fatal_active {
    background-color: #FDF6F6;
}

.fatal_active .para_fatal p {
    color: var(--bs-danger);
}

.sub_parameter_box:hover {
    background-color: #E9EBF4;
}

.sub_parameter_right {
    width: 100%;
    padding-left: 15px;
}

.sub_parameter_box .para_comman {
    padding: 0 2px;
}

.sub_parameter_box .move {
    min-width: 25px;
    cursor: move;
}

.para_comman .tlp-text {
    display: inline-block;
    vertical-align: middle;
    align-items: center;
    /* display: flex; */
}

.para_comman p{
    color: var(--bs-black);
    font-size: 13px;
    margin: 0 0 0px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: inherit;
}
.para_comman p.complince_tag_show
{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: inherit;
    word-break: break-all;
}
.para_comman .tlp-text-complince .eclips_text
{
    display: block;
}
.para_action .para_edit_ddn .dropdown-toggle {
    padding: 3px 4px;
    background-color: transparent;
    border: none;
}

.para_action .para_edit_ddn .dropdown-menu span {
    min-width: 21px;
    display: inline-block;
}

.para_action .para_edit_ddn .dropdown-item {
    display: flex;
    align-items: center;
    padding: 7px 15px;
}

.para_action .para_edit_ddn .dropdown-item:hover,
.para_action .para_edit_ddn .dropdown-item:focus {
    background-color: #eee;
    color: var(--dark);
}

.para_action .para_edit_ddn .dropdown-item:hover a {
    color: #000 !important;
}

.para_action .dropdown .dropdown-menu {
    min-width: 116px;
}

.para_action .para_edit_ddn .dropdown-item .dropdown-item {
    color: var(--dark);
}

.para_action .para_edit_ddn .dropdown-toggle::after {
    display: none;
}

.params_accordian_area .sub_parameter_box {
    margin-left: 0;
}

.params_accordian_area .parameter_list_wrap ul li:before,
.params_accordian_area .parameter_list_wrap ul li:after {
    display: none;
}

.params_accordian_area .accordion-button {
    padding-left: 45px;
}

.params_accordian_area .accordion-button.collapsed::after,
.params_accordian_area .accordion-button::after {
    content: "";
    width: 20px;
    background-size: 19px;
    display: block;
    position: absolute;
    left: 15px;
}

.params_accordian_area .accordion-button.collapsed::after {
    background: url('../images/add_icon_black.svg') no-repeat;
}

.params_accordian_area .accordion-button::after {
    background: url('../images/minus_icon_black.svg') no-repeat;
}

.params_accordian_area .accordion-item {
    margin-bottom: 15px;
    border: 1px solid #EEEEF2 !important;
    border-radius: 6px !important;
    overflow: hidden;
}
.complince_tag
{
    background-color: rgba(216, 255, 234, 1);
    padding: 4px 7px;
    border-radius: 36px;
    font-size: 12px;
    white-space: nowrap;
    margin-top: 2px;
}
/* tab */
.tab_view .nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
}

.tab_view .nav-pills .nav-item {
    margin-right: 20px;
    margin-bottom: -1px;
}

.tab_view .nav-pills .nav-link {
    font-size: 14px;
    color: var(--bs-dark-grey);
    font-weight: 500;
    padding: 8px 6px !important;
}

.tab_view .nav-pills .nav-link.active {
    color: var(--bs-primary);
    background-color: transparent;
    border-bottom: 2px solid var(--bs-brand_green);
    border-radius: 0;
}

.tab_view .nav-pills.flex-column {
    flex-direction: row !important;
}

.td_rebutal_comment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
}

.td_rebutal_comment a {
    color: #0844DF;
}

/*  */
.audit_btn {
    background-color: #EBEBEB;
    display: inline-block;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    color: #BDBDBD;
    font-size: 12px;
}
.audit_btn:hover{
    color: var(--bs-black);
}
.audit_btn.bordered_btn{
    background-color: transparent;
    color: var(--bs-primary);
}
.yellow_btn {
    background-color: var(--bs-yellow);
    color: var(--bs-black);
}

.success_btn {
    background: var(--bs-success);
    color: var(--bs-white);
}

.success_btn:hover {
    color: var(--bs-white);
    ;
}

.complete_btn {
    background: transparent;
    pointer-events: none;
    padding: 0;
}

.custom_upload.complete_btn {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
}

.custom_upload .remove_file {
    background: url('../images/close_icon.svg') no-repeat;
    height: 18px;
    width: 18px;
    display: inline-block;
    position: relative;
    top: 5px;
    cursor: pointer;
}

/* file upload button */
.custom_upload input[type="file"] {
    position: relative;
}

.custom_upload input[type="file"]::file-selector-button {
    width: 136px;
    color: transparent;
}

.custom_upload input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 9px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background: url("../images/upload_icon.svg") no-repeat;
}

.custom_upload input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 6px;
    left: 40px;
    color: var(--bs-primary);
    content: "Upload Audio";
}

.custom_upload input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 32px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
    border: 1px solid var(--bs-primary);
}

.custom_upload input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
.custom_upload input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}

/*  */
.vidget_comman {
    margin-bottom: 20px;
    word-break: break-all;
}

.vidget_comman h2 {
    color: var(--b-dark);
    font-size: 16px;
}

.vidget_comman .vidget_comman_inner {
    border: 1px solid #ccc;
    padding: 15px 10px;
    border-radius: 4px;
}

.audit_back {
    color: #000;
    font-size: 16px;
}
/* audit_trans_accordian */
.audit_trans_accordian .accordion-body
{
    font-size: 14px;
}
.audit_trans_accordian .accordion-item
{
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    overflow: hidden;
}
.audit_trans_accordian .accordion-button
{
    padding: 9px 10px;
    background-color: transparent;
}
.audit_trans_accordian .accordion-header
{
    border: 1px solid #EEEEF2;
    border-radius: 4px !important;
}
.audit_trans_accordian .accordion-button h5{
    margin: 0;
    color: #424a54;
    font-size: 14px;
}
.audit_trans_accordian .accordion-body
{
    padding: 15px 10px 15px 10px;
    border: 1px solid #EEEEF2;
    margin-top: 10px;
    border-radius: 4px;
}
.overall_sentimate_res h6
{
    font-size: 11px;
}
.overall_sentimate_res h6 strong
{
    font-weight: 600;
}
/*  */
.rebuttal_vidget .tab_view .nav-pills .nav-item
{
    margin-right: 5px;
}
.audit_details_vidget .audit_vidget_head h4 {
    color: var(--b-dark);
    font-size: 16px;
}
/* .sentimate_anlys_div .accordion-button::after
{
    display: none;
}
.sentimate_anlys_div .accordion-button:not(.collapsed)
{
    background-color: transparent;
}
.sentimate_anlys_div h5.sentimate_result
{
    font-weight: 400;
    font-size: 13px;
} */
.audit_details_view {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 13px;
}

.rebutal_comment_list {
    margin-top: 20px;
}

.rebutal_comment_views {
    display: flex;
    margin-bottom: 32px;
}

.rebutal_comment_views .media_img {
    margin-right: 10px;
    height: 36px;
    width: 36px;
    border: 1px solid #ccc;
    border-radius: 50%;
    min-width: 36px;
    overflow: hidden;
}

.rebutal_comment_views .media_img img,
.rebutal_comment_inp .chat_media_img img {
    width: 100%;
}

.rebutal_comment_views .media_body {
    width: 100%;
}

.rebutal_comment_views .media_name h4 {
    color: var(--b-dark);
    font-size: 14px;
    line-height: 1.3;
}

.rebutal_comment_views .media_name h4 strong {
    font-weight: 600;
}

.rebutal_comment_views .media_body .date_time h5 {
    font-size: 14px;
    text-align: right;
}

.rebutal_comment_views .media_body p {
    color: #484964;
    margin: 0 0 0 0;
}

.rebutal_track ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.rebutal_track ul li {
    display: inline-block;
    margin-right: 22px;
    position: relative;

}

.rebutal_track ul li:before {
    content: "";
    background: url('../images/right_arrow.svg') no-repeat;
    position: absolute;
    left: -18px;
    width: 12px;
    height: 12px;
    top: 6px;
}

.rebutal_track ul li:first-child:before {
    display: none;
}

.rebutal_track ul li:last-child {
    margin-right: 0;
}

.rebutal_track ul li .btn {
    padding: 3px 5px;
    color: #444;
    /* border: 1px solid #ccc; */
    font-size: 10px;
    font-weight: 600;
    cursor: auto;
}

.rebutal_comment_views .rebutal_msg {
    margin: 10px 0 0 0;
    word-break: break-all;
}

.rebutal_msg .autoAcceptedMessage {
    background-color: #fdf0e3;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #f0decc;
}

.rebutal_comment_inp {
    display: flex;
    align-items: center;
}

.rebutal_comment_inp .chat_media_img {
    margin-right: 10px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    min-width: 36px;
    overflow: hidden;
    background-color: #eee;
}

.rebutal_comment_inp .form-control {
    height: 50px;
}

.cus_pagignation ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.cus_pagignation ul li {
    color: #ABACBE;
    font-size: 15px;
    margin-right: 10px;
}

.cus_pagignation ul li.current {
    color: #646573;
}

.cus_pagignation ul li.prev,
.cus_pagignation ul li.next {
    cursor: pointer;
}

.cus_pagignation ul li.next {
    color: var(--bs-primary);
    font-weight: 500;
}

.ticket_id h3 {
    font-size: 16px;
    color: var(--b-dark);
}

.audio_player {
    display: flex;
    padding: 0px 0px;
    /* margin-bottom: 30px; */
    width: 100%;
}

.audio_player audio {
    min-width: 240px;
    width: 100%;
}

.audio_player>div {
    width: 100%;
}

.audio_player .audio_controls {
    min-width: 84px;
}

.audio_player .audio_controls .play {
    text-align: center;
}

.audio_player .audio_controls .play .play_btn {
    width: 35px;
    height: 35px;
    background-color: #117DE6;
    color: var(--bs-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    border: none;
    text-align: center;
    margin: 0 auto;
}
/* audit transcription */
.audit_transcription {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 320px;
    padding-right: 6px;
}
.audit_transcription .trans_list
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
}

.audit_transcript_bx p {
    margin: 0 0 5px 0;
    font-size: 15px;
}

.audit_transcript_bx p .t_name {
    font-weight: 600;
}

.table_score {
    display: flex;
    justify-content: flex-end;
}

.table_score ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #e2e5e8;
    border-bottom: none;
    display: inline-block;
}

.table_score ul li {
    display: inline-block;
    padding: 6px 20px;
    color: #484964;
    font-size: 14px;
}

.table_score ul li strong {
    font-size: 15px;
    font-weight: 600;
}

.table_score ul li .pill {
    padding: 4px 9px;
    border-radius: 30px;
    display: inline-block;
    background-color: #F1FAF1;
    color: #0E700E;
    border: 1px solid #9FD89F;
    margin-left: 7px;
}

.td_status .btn {
    margin-right: 6px;
    padding: 0;
    margin-bottom: 3px;
    margin-top: 3px;
    color: #98A2B3;
    border-color: #bcbdbf !important;
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    border-radius: 50px !important;
}
.td_status .btn:hover{
    background-color: #fff;
    border-color: #EAECF0 !important;
}
.td_status .btn span
{
    padding: 2px 8px;
}

.td_status .btn span {
    display: block !important;
}

.td_status .btn_na {
    color: #616161;
}

.permission_comment {
    border: 1px solid #d0d5dd;
    border-radius: 4px;
}

.permission_comment .form-control {
    height: 90px;
    border-radius: 0;
    border: none;
    background: transparent;
    resize: none;
    padding: 15px;
}

.permission_comment .comment_custamizer {
    padding: 10px 15px 11px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.font_formate .font_btn {
    border: none;
    background: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 4px 7px;
    line-height: 1;
    margin-right: 3px;
}

.permission_comment::placeholder {
    color: #ABACBE;
}

.permission_comment .form-control::placeholder {
    color: #ABACBE;
    font-size: 14px;
    letter-spacing: 0.4px;
}

.comment_custamizer .chat_send ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.comment_custamizer .chat_send ul li {
    display: inline-flex;
    align-items: center;
}

.comment_custamizer .chat_send ul li .sound_record {
    background-color: #EBF3FC;
    padding: 5px 6px;
    border: 1px solid #B4D6FA;
}

.comment_custamizer .chat_send .attachment_file {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.comment_custamizer .chat_send .attachment_file input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.comment_custamizer .chat_send .attachment_file .btn {
    padding: 5px 7px;
}

/*  */
.permission_button_box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    z-index: 1;
    position: relative;
    background-color: #fff;
}

.permission_button_box .btn {
    margin: 3px 10px;
}

.success_msg_box {
    background-color: #5FD25533;
    padding: 8px 40px;
    max-width: 92%;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    color: #201F1E;
}

/* chat auditing */
.chatbot_body .iq-other-user.iq-message-body,
.iq-current-user.iq-message-body {
    margin-right: 10px;
}

.chatbot_body .iq-chating-content {
    max-width: 90%;
}

.chatbot_body .iq-current-user .iq-chating-content {
    background-color: #007AFF;
    color: #fff;
}

.chatbot_body .iq-other-user .iq-chating-content {
    background-color: #F2F2F7;
    color: #000;
}

.chatbot_body .iq-chating-content p {
    line-height: 1.3;
    font-size: 13px;
}

.chatbot_body .iq-message-body {
    margin-bottom: 10px;
}

.chatbot_body .chat-profile {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

/* email audit */
.audit_vidget_toggle {
    display: none;
}

.chat_vidget_active {
    width: 100% !important;
}

.social_audit_comman {
    position: relative;
}

.social_audit_comman .chat_toggle_arrow {
    background-color: #000;
    width: 17px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: -27px;
    top: 1px;
    cursor: pointer;
}

/* email */
.email_body .accordion-item {
    border: none;
}

.email_body .accordion-body {
    padding: 10px 10px;
}

.email_body .accordion-button {
    padding: 5px 10px;
    background-color: #F5F5F5;
    justify-content: space-between;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 5px;
    border-radius: 0 !important;
}

.email_body .email_head_left .email_head_user_img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    min-width: 35px;
    margin-right: 10px;
}

.email_body .email_head_left .email_head_name h4 {
    font-size: 14px;
    color: #000;
    margin: 0;
}

.email_body .email_head_left .email_head_name h5 {
    font-size: 11px;
}

.email_body .email_head_right {
    font-size: 12px;
    min-width: 77px;
    width: 100%;
}

.email_body .mail_comment p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.email_body .email_head_right .email_date {
    min-width: 77px;
    color: var(--b-dark);
}

.email_head_name h4 {
    font-size: 14px;
    margin: 0 0 2px 0;
}

.email_head_name h5 {
    font-size: 13px;
}

.email_body .cc_mail h6 {
    font-size: 10px;
    color: #8B8B8B;
}

.email_body .cc_dropdown {
    border: 1px solid #eee;
    margin-left: 3px;
    padding: 0px 3px;
}

.email_body .accordion-button::after {
    display: none;
}

/*  */
.alret_modal .modal-dialog {
    max-width: 523px !important;
}

.alret_modal .modal-header {
    border-bottom: none;
}

.alret_modal_body {
    padding: 0px 20px 20px 20px;
}

.alret_modal_body .alret_modal_bottom .link_btn {
    color: var(--bs-black);
}

.alret_modal_body h3 {
    font-size: 20px;
    color: #484964;
    margin: 0 0 10px 0;
}

.alret_modal_body h4 {
    font-size: 18px;
    color: #1a202c;
    font-weight: 600;
}

.alret_modal_body .alret_modal_bottom {
    margin-top: 40px;
    text-align: right;
}

.alret_modal_textarea textarea {
    height: 120px;
}

/* dashboard */
.dash_graph .header-title h5 {
    font-size: 16px;
    color: #344054;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}

.calendarWrap {
    position: relative;
}

.calendarWrap .rdrDateRangePickerWrapper {
    position: absolute;
    left: 0;
    z-index: 99;
    border: 1px solid var(--bs-border-color);
    border-radius: 4px;
    overflow: hidden;
}
.calendarWrap .rdrStartEdge.rdrEndEdge
{
    /* background-color: var(--bs-brand_green); */
}
.calendarWrap .rdrMonthAndYearPickers select {
    background-size: 12px;
}

.calendarWrap .rdrDefinedRangesWrapper {
    width: 190px;
}

.calendarWrap .rdrCalendarWrapper {
    max-width: 590px;
    width: 100%;
}

.calendarWrap .rdrDayToday .rdrDayNumber span:after {
    display: none;
}
.drpdown_tree_select .search::placeholder {
    color: #1a202c;
    opacity: 1; 
  }



.dash_comman_box .card_link {
    display: block;
    height: 100%;
}

.qa_dash_left .dash_comman_box .card {
    min-height: 139px;
    margin-bottom: 15px;
}

.qa_dash_right .dash_comman_box .card {
    min-height: 108px;
    margin-bottom: 5px;
}

.qa_dash_right .qa_dash_right_cardbody {
    padding-bottom: 0 !important;
}

.qa_dash_right .dash_small_box .card-body {
    padding: 0;
}

.quality_fun_dashboard .card-body {
    padding: 15px;
}

.quality_fun_dashboard .dash_card_success .card-body {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.dash_media {
    display: flex;
    justify-content: center;
}

.dash_media .dash_content h3 {
    font-size: 17px;
    color: #484964;
    margin: 0 0 3px 0;
    min-height: 44px;
}

.dash_content_bottom h2 {
    font-size: 24px;
    color: #484964;
    margin: 0;
}

.dash_small_box .card {
    border: none;
    box-shadow: none;
}

.agent_score_card .score_media {
    display: flex;
}

.agent_score_card .score_media .score_icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5E9FF;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.agent_score_card .score_media h3 {
    font-size: 16px;
    color: #484964;
    margin: 0 0 3px 0;
}

/* master dashboard page */
.radio_tab_area .nav-pills {
    justify-content: center;
}

.radio_tab_area .nav-pills .nav-item .nav-link {
    display: flex;
    border: 2px solid transparent;
    align-items: center;
    padding: 5px 12px !important;
}

.radio_tab_area .nav-pills .nav-item span {
    position: relative;
    width: 17px;
    height: 17px;
    display: inline-flex;
    border: 1px solid #ccc;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.radio_tab_area .nav-pills .nav-item .nav-link.active {
    background-color: transparent;
    border-color: var(--bs-primary);
    color: var(--bs-primary);
    border-radius: 3px;
}

.radio_tab_area .nav-pills .nav-item .nav-link.active span {
    border-color: var(--bs-primary);
}

.radio_tab_area .nav-pills .nav-item .nav-link.active span:before {
    content: "\f111";
    font-family: 'FontAwesome';
    font-size: 14px;
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-51%, -50%);

}

.radio_tab_area .nav-pills .nav-item .tab_radio {
    position: relative;
    width: 17px;
    height: 17px;
    display: inline-flex;
    border: 1px solid #ccc;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.radio_tab_area .nav-pills .nav-item .tab_radio .tab_radio_circle {
    width: 11px;
    height: 11px;
    background-color: var(--bs-white);
    border-radius: 100%;
}

.radio_tab_area .nav-pills .nav-item .nav-link.active .tab_radio .tab_radio_circle {
    border-color: var(--bs-brand_green);
    background-color: var(--bs-brand_green);
}

/* data table sorting override */
table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_asc_disabled:after,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>td.sorting:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_asc:after,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>td.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_asc_disabled:after,
table.dataTable thead>tr>td.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:after {
    color: #000;
}

/* manual_integration_area page */
.no_card_data .agent_score_card .score_media h3,
.no_card_data .agent_score_card h2 {
    opacity: 0.6;
}

.upcomming_data_area {
    height: 324px;
    background-color: #D9D9D966;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcomming_data_area p {
    color: #928A8A;
}

.crm_error_modal_body {
    padding: 15px 15px;
}

/* start */
.cus_excel_upload {
    display: inline-block;
    vertical-align: middle;
}
.cus_excel_upload .file-upload-wrapper {
    position: relative;
    width: 147px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
}

.cus_excel_upload .file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 11px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 40px;
    line-height: 1;
    color: #999;
    border-radius: 0;
    font-weight: 300;
    border: 1px solid #D9D9D9;
}

.cus_excel_upload .file-upload-wrapper:before {
    content: "Browse Excel";
    background-image: url('../images/excel_icon2.svg');
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 100%;
    background-color: var(--bs-primary);
    color: #fff;
    z-index: 25;
    font-size: 14px;
    line-height: 1;
    pointer-events: none;
    border-radius: 4px;
    padding: 13px 12px 11px 40px;
    background-repeat: no-repeat;
    background-position: top 10px left 15px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Inter", sans-serif !important;
    width: 100%;
}


.cus_excel_upload .file-upload-wrapper.disabled:before {
    background-color: #F0F0F0;
    color: #d3d3d3;
    border: 1px solid #E0E0E0;
}

.cus_excel_upload .file-upload-wrapper.disabled:hover:before {
    background-color: #F0F0F0 !important;
}
.cus_excel_upload .file-upload-wrapper:hover:before {
    background-color: var(--bs-brand_medium_green) !important;
}
.cus_excel_upload .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
}

.cus_excel_upload .file-upload-wrapper input:before {
    content: "..";
}

/* end */

.new_crm_filter {
    justify-content: flex-end;
}

.new_crm_filter .btn:disabled,
.new_crm_filter .btn.disabled {
    background-color: #F0F0F0 !important;
    /* border: 1px solid var(--bs-border-color); */
    color: #BDBDBD;
    padding: 7px 14px;
    font-size: 14px;
}

.new_crm_filter .table_exp_ddn .dropdown-item {
    padding: 8px 13px;
    font-weight: 500;
}

.new_crm_filter .table_exp_ddn .dropdown-item a {
    color: #616161;
}

.new_crm_filter .table_exp_ddn .dropdown-item:hover a {
    color: #fff;
}

.new_crm_filter .exp_btn.btn {
    /* border-color: var(--bs-primary); */
    /* color: var(--bs-primary); */
}

.new_crm_filter .dropdown-toggle {
    position: relative;
}

.new_crm_filter .dropdown-toggle::after {
    content: "\f107" !important;
    font-family: 'FontAwesome';
    border: none;
}

.crm_error_modal .modal-dialog {
    max-width: 89% !important;
}

.crm_error_modal .modal-header {
    border-bottom: none;
}

.alret_success_modal .modal-dialog {
    max-width: 488px !important;
    width: 100%;
}

.alret_success_modal .modal-body {
    padding: 40px 30px;
}

.chat_message_repeat .chat_message_txt p {
    margin: 0;
    font-size: 13px;
    line-height: 1.3;
}

.chat_message_repeat h6 {
    margin: 7px 0 0 0;
    font-size: 11px;
    font-weight: 400;
}

/* dashboard */

.dash_graph .header-title h4 {
    font-size: 24px;
}
.dash_graph .header-title p {
    font-size: 14px;
    margin: 0;
}

.qa_dash_right .card {
    border-color: #DD9698;
}

.dash_media .dash_icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.dash_media .dash_icon.bg_dash {
    background-color: #E5E9FF;
}

.dash_content_bottom h4 {
    font-size: 11px;
    color: #484964;
    margin: 5px 0 0 0;
}

.dash_card_danger .dash_media .dash_icon {
    border: 1px solid #BC2F32;
}

.dash_card_primary .dash_media .dash_icon {
    border: 1px solid #B4D6FA;
}

.dash_card_success .dash_media .dash_icon {
    border: 1px solid #107C10;
}

.agent_score_card_box {
    display: flex;
}
.agent_score_card_box .card{
    box-shadow: none;
}
.agent_score_card .card {
    margin-bottom: 15px;
}

.agent_score_card .score_media {
    display: flex;
}

.agent_score_card h2 {
    font-size: 20px;
    color: #484964;
    margin: 0 0 0px 0;
    font-weight: 600;
}

.agent_score_wise .agent_score_card_box .total_fatal_card {
    border-color: var(--bs-danger);
}

.agent_score_wise .agent_score_card_box .total_rebuttal_card {
    border-color: #817400;
}

.total_rebuttal_card .agent_score_card .score_media h3,
.total_rebuttal_card .agent_score_card h2 {
    color: #817400;
}

/*  */
.table_exp_area {
    justify-content: flex-end;
}

.table_exp_area .btn {
    background-color: transparent;
    border: 1px solid #ccc;
}

.table_exp_area .table_exp_ddn .dropdown-item {
    padding: 8px 13px;
    font-weight: 500;
}

.table_exp_area .table_exp_ddn .dropdown-item a {
    color: #616161;
}

.table_exp_area .table_exp_ddn .dropdown-item:hover a {
    color: #fff;
}

.table_exp_area .exp_btn.btn {
    border-color: var(--bs-primary);
    color: var(--bs-primary);
}
.table_exp_area .exp_btn.btn:hover{
    color: var(--bs-primary);
    border-color: var(--bs-primary);
    background-color: var(--bs-brand_light_green);
}

.table_exp_area .dropdown-toggle {
    position: relative;
}

.table_exp_area .dropdown-toggle::after {
    content: "\f107" !important;
    font-family: 'FontAwesome';
    border: none;
}

.agent_tbl_scorewise .td_agent_name {
    min-width: 117px;
}

.agent_tbl_scorewise th {
    min-width: 60px;
}

.agent_tbl_scorewise .th_no_audit {
    min-width: 89px;
}

.agent_tbl_scorewise .td_eID,
.agent_tbl_scorewise .td_total_audit {
    border-left: 1px solid #cccc;
}

.th_cus_sorting {
    display: inline-flex;
    align-items: center;
}

.th_cus_sorting .td_sorting_bx {
    margin-left: 6px;
    color: #ccc;
    cursor: pointer;
}

.th_cus_sorting .td_sorting_bx div {
    line-height: 1;

}

.th_cus_sorting .td_sorting_bx .th_active {
    color: #444;
}

/* data table sorting override */
table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_asc_disabled:after,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>td.sorting:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_asc:after,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>td.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_asc_disabled:after,
table.dataTable thead>tr>td.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:after {
    color: #000;
}

/* manual_integration_area page */
.no_card_data .agent_score_card .score_media h3,
.no_card_data .agent_score_card h2 {
    opacity: 0.6;
}

.upcomming_data_area {
    height: 324px;
    background-color: #D9D9D966;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcomming_data_area p {
    color: #928A8A;
}

.crm_error_modal_body {
    padding: 15px 15px;
}



.new_crm_filter {
    justify-content: flex-end;
}


.new_crm_filter .bordered_btn {}

.new_crm_filter .table_exp_ddn .dropdown-item {
    padding: 8px 13px;
    font-weight: 500;
}

.new_crm_filter .table_exp_ddn .dropdown-item a {
    color: #616161;
}

.new_crm_filter .table_exp_ddn .dropdown-item:hover a {
    color: #fff;
}

.new_crm_filter .dropdown-toggle {
    position: relative;
}
.new_crm_filter .dropdown-toggle:disabled{
    opacity: 0.7 !important;
}
.new_crm_filter .dropdown-toggle::after {
    content: "\f107" !important;
    font-family: 'FontAwesome';
    border: none;
}

.crm_error_modal .modal-dialog {
    max-width: 89% !important;
}

.crm_error_modal .modal-header {
    border-bottom: none;
}

.alret_success_modal .modal-dialog {
    max-width: 488px !important;
    width: 100%;
}

.alret_success_modal .modal-body {
    padding: 40px 30px;
}

.filter_setup_form_area {
    padding: 15px 15px;
    border-radius: 6px;
    border: 1px solid var(--bs-border-color);
}

.reassign_modal .modal-dialog {
    max-width: 688px;
}

.reassign_modal .modal-header {
    border-bottom: none;
}

.modal_score_row {
    align-items: center;
}

.modal_score_row .td_img_modal,
.modal_score_row .card {
    margin-bottom: 10px;
}

.modal_score_row .card-body {
    padding: 15px 15px;
}

.td_img_modal {
    color: #484964;
}

.radio_tab_area .nav-pills .nav-item .nav-link.active span:before {
    line-height: 0;
}

.radio_tab_area {
    margin-top: 10px;
}

.decoration_line {
    text-decoration: underline;
    color: var(--bs-primary);
}
.decoration_line:hover{
    color: var(--bs-brand_medium_green);
}

/*  */
.add_custom_filed_modal .modal-dialog {
    max-width: 940px !important;
}

/* .alret_modal .modal-header
{
    border-bottom: none;
} */
/* survey js */
.survey_test {}

.survey_test .sd-root-modern--full-container {
    background-color: transparent;
}

.survey_test .sd-body.sd-body--static {
    max-width: 100%;
    padding-top: 0;
}

.survey_test .sd-body .sd-body__page {
    padding: 0;
}

.survey_test .sd-page__row.sd-row--compact {
    margin-top: 10px;
}

.survey_test .sd-row__question {
    padding: 0;
    box-shadow: none;
    padding-left: 2px;
}

.survey_test .sd-item {
    padding: 0px 0;
    margin: 0 0 14px 0;
}

.survey_test .sd-element__title .sv-string-viewer {
    color: var(--dark);
    font-size: 16px;
    font-weight: 500;
}

.survey_test .sd-selectbase__label .sd-item__control-label {
    line-height: 1;
    font-size: 14px;
}

.survey_test .sd-radio__decorator {
    border: 1px solid #D1D1D1;
    box-shadow: none;
    background-color: transparent;
    height: 16px;
    width: 16px;
}

.survey_test .sd-item--checked .sd-item__decorator {
    background: var(--sjs-primary-backcolor, var(--primary, #273894));
}

.survey_test .sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
    background-color: transparent;
}

.sd-radio__decorator:after {}

.survey_test .sd-item--checked .sd-item__decorator {
    border: 1px solid var(--bs-brand_medium_green);
    width: 16px;
    height: 16px;
    background-color: white !important;
}

.survey_test .sd-radio--checked .sd-radio__decorator:after {
    background: var(--bs-brand_medium_green);
}

.survey_test .sd-radio__control:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.survey_test .sd-radio--checked .sd-item__control-label {
    color: var(--bs-brand_medium_green);
}

.survey_test .sd-item__control:focus+.sd-item__decorator {
    box-shadow: none;
}

.survey_test .sd-title .sv-title-actions {
    width: 100%;
}

.survey_test .sd-element__title .sd-element__num {
    margin-left: -16px;
    color: var(--dark);
    font-size: 16px;
    font-weight: 500;
}

.survey_test .sv-action__content .sd-action {
    padding: 8px 16px;
    border-radius: 4px;
    color: #545454;
    font-weight: 400;
}

.survey_test .sv-action__content .sd-action .sd-element__title span {
    font-size: 14px;
    font-weight: normal;
}

.survey_test .sv-action__content .sd-action:focus {
    color: #fff;
}

.survey_test.exam_disabled {
    opacity: 0.6;
    padding-top: 10px;
}

.exam_disabled .test_res_report_area .test_result_box li.attempted_answer {
    /* color: red; */
}

/* timer part */
.padd_right_timer
{
    padding-right: 130px;
}
.survey_test .sd-timer {
    box-shadow: none;
    width: 108px;
    height: 108px;
    padding: 0;
    margin-top: 40px;
}

.survey_test .sd-timer__text--major {
    color: var(--bs-primary);
    font-size: 22px;
    margin: 0 0 3px 0;
    line-height: 1;
}

.survey_test .sd-timer__text--minor {
    font-size: 16px;
    line-height: 1;
}

/*  */

.ai_assist_btn_div {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
}
.ai_assist_btn_div .ai_tooltip {
    background-color: #000;
    color: #fff;
    padding: 8px 12px;
    display: none;
    position: absolute;
    top: -42px;
    right: -3px;
    border-radius: 4px;
    line-height: 1;
    min-width: 172px;
    font-size: 13px;
    text-align: center;
}
 
.ai_assist_btn_div:hover .ai_tooltip {
    display: inline-block;
}
.ai_assist_btn {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bs-primary);
    color: #fff;
    border-radius: 50%;
    overflow: hidden;
    line-height: 1.2;
    z-index: 1;
}
.ai_assist_btn_div .btn__spinner {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.2s ease-out;
  }
  .ai_assist_btn_div .btn__spinner:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #23ce6b 50%, #23ce6b 100%);
  }
  .ai_assist_btn_div .btn__spinner {
    transform: scale(1.2);
  }
  .ai_assist_btn_div .btn__spinner:before {
    animation: loop 5s linear infinite;
  }

  @keyframes loop {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.survey_test .sd-timer__progress--animation {}

.sd-timer__progress {}

/* for hiding Question 1 Score 10 */
.survey_test .sd-question__content .sd-html h4 {
    display: none;
}

.survey_test .sd-action:not(.sd-action--pressed):hover {
    color: var(--bs-primary);
    background-color: #DFE1EF;
}

.agent_tbl_scorewise .td_status {
    min-width: 235px;
}

.audit_status_width_only .table tr th:nth-child(2) {
    min-width: 200px;
}
/* DIY CSS Start*/
.obs_single_user_modal .modal-dialog {
    max-width: 70%;
}

.obs_single_user_modal_bulk .modal-dialog {
    max-width: 80%;
}
.obs_single_user_modal_bulk .css-1u9des2-indicatorSeparator
{
    display: none;
}
.obs_single_user_modal .select_width {
    min-width: 100px;
}

.useronboard_search {
    position: relative;
    max-width: 330px;
    width: 100%;

}

.useronboard_search .form-control {
    padding-right: 55px;
}

.useronboard_search .diy_emp_cancel_btn {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 42px;
    top: 0;
    border-radius: 50%;
    border: none;

}

.useronboard_search .diy_emp_search_btn {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 4px;
    top: 3px;
    border-radius: 50%;
    border: none;
    background-color: var(--bs-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.useronboard_search .diy_emp_search_btn i {
    color: var(--bs-white);
}
.useronboard_search .diy_emp_search_btn:hover{
    background-color: var(--bs-brand_medium_green);
}
.diy_user_tbl {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 264px);
    padding-right: 6px;
}
.diy_user_tbl .text_ellipses
{
    max-width: 70px;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
}
/* DIY */
.drag_file_upload_area {
    border: 1px dashed #ccc;
    padding: 60px 40px;
}
.drag_file_upload_area .drag_file_upload_inner
{
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}

.file_upload_custom {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.file_upload_custom .btn {
    border: 1px solid var(--bs-primary);
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 16px;
}

.file_upload_custom input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.drag_file_upload_area h5{
    color: #344054;
}
/*  */
/* diy single user modal */
.div_table_style_diy {
    border: 1px solid #e2e5e8;
    padding: 0 8px;
}

.div_table_style_diy .row {
    margin-left: -8px;
    margin-right: -8px;
}

.div_table_style_diy .col_th,
.div_table_style_diy .col_td {
    padding: 8px 8px;
    font-size: 13px;
    width: 10%;
    display: flex;
    align-items: center;
}

.div_table_style_diy .col_obs_width {
    width: 12%;
}

.div_table_style_diy .col_th {
    background-color: #F9F9FC;
    font-weight: 700;
    border-bottom: 1px solid #e2e5e8;

}

/* .div_table_style_diy .react-dropdown-tree-select .dropdown .dropdown-trigger {
    z-index: auto !important;
} */

.div_table_style_diy .react-dropdown-tree-select .dropdown .dropdown-content {
    padding: 8px 8px !important;
}

.div_table_style_diy .react-dropdown-tree-select .dropdown .dropdown-trigger {
    max-width: 120px;
    width: 100%;
}

.div_table_style_diy .react-dropdown-tree-select .dropdown ::-webkit-scrollbar {
    height: 6px;
}

.div_table_style_diy .react-dropdown-tree-select .dropdown ::-webkit-scrollbar-thumb {
    background: var(--bs-primary);
}

.div_table_style_diy .react-dropdown-tree-select .dropdown ::-webkit-scrollbar-thumb:hover {
    background: var(--bs-primary);
}

.div_table_style_diy .drpdown_tree_select .tag-item:not(:first-child) {
    display: block;
}

.div_table_style_diy .tag-remove {
    right: 27px;
    background-color: #ccc;
}

.div_table_style_diy .react-dropdown-tree-select .tag-list .tag:hover::after {
    bottom: -55px;
}

.div_table_style_diy .not_created_diy {
    padding: 4px 12px;
    border: 1px solid #E0E0E0;
}

.div_table_style_diy .react-dropdown-tree-select .dropdown .dropdown-content {
    width: 220px;
}
.timeDropDownAccordian .accordion-button:not(.collapsed)
{
    color: var(--bs-dark-grey);
}
.timeDropDownAccordian .accordion-button
{
    padding: 11px 15px !important;
}
.no_wrap_lbl label
{
    white-space: nowrap;
}
.Toastify .Toastify__toast-body
{
    font-family: "Inter", sans-serif !important;
}
/*========= responsive =============*/
@media (min-width: 1600px) {
    .sidebar_right_nav {
        /* padding-left: 11px; */
    }

    .agent_tbl_scorewise .td_status {
        min-width: 230px;
    }

}
@media (min-width: 1422px) {
    .overall_sentimate_res h6 {
        font-size: 13px;
    }

}

@media only screen and (min-width:320px) and (max-width:1199px) {
    .agent_tbl_scorewise .td_status {
        min-width: 200px;
    }
}

@media only screen and (min-width:320px) and (max-width:1199px) {
    .content-inner {
        grid-template-columns: 0px 1fr !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .radio_tab_area .nav-pills {
        flex-direction: row !important;
    }

    .radio_tab_area .nav-pills .nav-item .nav-link {
        border: none;
    }

    .false_div {
        display: none;
    }
}

@media only screen and (min-width:320px) and (max-width:992px) {
    .content_wrap_comman {
        height: 100%;
        margin-bottom: 20px;
    }

    .parameter_view {
        background-color: transparent;
    }

    .parameter_view .content_wrap_comman {
        background-color: #F4F4F9;
        padding: 25px 15px 15px 15px;
    }
}

@media only screen and (min-width:767px) and (max-width:992px) {

    .sidebar-right {
        min-height: 100vh;
        top: 0;
    }

    .sidebar_right_nav {
        width: 30%;
    }

    .content_wrap_main {
        width: 70%;
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .sidebar_right_nav {
        width: 100%;
    }

    .content_wrap_main {
        width: 100%;
    }

    .sidebar-right {
        min-height: 100%;
        top: 0;
    }

    .sidebar_right_nav {
        padding-left: 15px;
    }

    .head_user_desig {
        display: none;
    }
}

/* VALIDATE LOADER */
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.input_editable {
    height: 22px;
}

.app-version {
    position: fixed;
    bottom: 8px;
    right: 8px;
    z-index: 3;
}

.modal-90w {
    max-width: 95%;
}

.modal-845 {
    max-width: 845px;
}

.modal-845 .modal-body .form_width_comman {}

.audit_edit_modal_body {
    max-height: calc(100vh - 150px);
    overflow: hidden;
    overflow-y: auto;
    padding-right: 15px;
}

/* 404 error page */
.error_page_nav {
    box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.07);
    padding: 7px 0;
}

.error_page_sec {
    padding: 30px 0;
    height: calc(100vh - 59px);
    align-items: center;
    display: flex;
}

.error_page_right {
    padding-top: 30px;
}

.error_page_area h2 {
    color: #000;
    font-size: 64px;
    font-weight: 500;
    margin: 0;
}

.error_page_area h3 {
    color: #000;
    font-size: 56px;
    margin: 0 0 15px 0;
    /* font-family: 'Segoe UI 500'; */
}

.error_page_area p {
    color: #000;
    font-size: 18px;
}

.was-validated #searchParam.invalid [class*='-control'] {
    border: solid 1px #D13438;
}

.was-validated #require.invalid  {
    border: solid 1px #D13438 ;
}

#searchParam.invalid [class*='-control'] {
    box-shadow: none;
}

.was-validated #searchParam.invalid~#searchError {
    display: block !important;
}

.was-validated .invalid~#datePickerError {
    display: block !important;
}

.materSaveBtn {
    display: flex;
    justify-content: center;

}

.masterSaveBtnDiv {
    background-color: #F4F4F9;
    padding: 20px 0px;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 3;
    left: 15px;
}

.paraPreviewCardDiv {
    max-height: calc(90vh - 160px);
    overflow: hidden;
    overflow-y: auto;
}

.paraPreviewCardDiv::-webkit-scrollbar {
    width: 6px;
    background: rgba(39, 56, 148, 0);
}

.paraPreviewCardDiv::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); */
}

.paraPreviewCardDiv::-webkit-scrollbar-thumb {
    background-color: #d0d5dd;
    outline: none;
    border-radius: 50px;
    width: 6px;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.shimmer {
    width: 100%;
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    border-radius: 8px;
}


#searchParam [class$="-option"] {
    background-color: white !important;
    color: var(--bs-black) !important;
}

#searchParam [class$="-option"]:hover {
    background-color: #f4f4f4 !important;
    color: var(--bs-primary) !important;
}

/* audit parameter breack word */
#searchParam > div > div > div
{
    grid-template-columns: inherit !important;
}
#searchParam > div > div > div:after{
    display: none;
}
#searchParam input{
    grid-area: 1 / 1 !important;
}

.head {
    color: #000;
}

.ReactTable {
    .rt-tbody .rt-td {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .custom_upload {
            width: 100%;
        }

    }
}

.waveform {
    display: flex;
    align-items: center;
    max-width: 100vw;
    margin-bottom: 6px;
    margin-top: 0px;
    padding: 5px 0;
    border-radius: 5px;
}

.barHolder {
    flex: 1;
    position: relative;
}

.bar {
    height: 100%;
    background: #0D91F5;
    border-radius: 15px;

}

.barOverHolder {
    width: 100%;
    position: absolute;
    height: 100%;
    border-radius: 15px;
    top: 0;
    display: flex;
}

.barOver {
    width: 100%;
    border-radius: 15px;
    margin: auto;
    background: #52D017;
    transition: height 10ms;
}
.wave_audio_box
{
    border: 1px solid #eaecf0;
    padding: 4px 10px;
    border-radius: 4px;
}
/* temp wave form */
.custom-audio-player {
    height: 25px; /* Adjust height to make it smaller */
}
.wave_line_wrap
{
}
.wave_line_wrap .wave_left_false
{
    /* width: 57px; */
}
.wave_line_wrap .wave_left
{
    width: 136px; 
    padding-right: 7px;
}
.wave_line_wrap .wave_right
{
    width: calc(100% - 213px);
    position: relative;
}
.wave_line_wrap .wave_right_false
{
    width: 99px;
}
.wave_line_wrap .wave_left .call_agent2
{
    margin-top: 29px;
}
.wave_line_wrap .wave_left p
{
    font-size: 12px;
    font-weight: 600;
}
/** Audo file **/
.audio-url.invalid {
    border: solid 1px #D13438;
}

.audio-url.invalid+.invalid-feedback {
    display: block !important;
}

.empty-element {
    margin: 0;
    padding: 50px 0;
    text-align: center;
}

.empty-element .img-block {
    max-width: 268px;
    margin: 0 auto 35px;
    padding: 0;
}

.empty-element .img-block img {
    width: 100%;
    height: auto;
}

.empty-element p {
    color: var(--bs-dark-grey);
    max-width: 425px;
    margin: 0 auto;
    padding: 0;
}

.progress-wrap {
    display: flex;
    align-items: center;
    margin: 12px 0 0 0;
    padding: 0;
}

.progress-wrap .icon-img {
    width: 44px;
    margin: 0 15px 0 0;
    padding: 0;
}

.progress-wrap .icon-img img {
    width: 100%;
    height: auto;
}

.progress-wrap .prg-line {
    flex: 1;
}

.progress-wrap .prg-line .md-5 {
    margin-bottom: 5px;
}

.progress-wrap .prg-line p {
    color: #484964;
    margin: 0;
    padding-right: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.progress-wrap .delete-btn {
    background: none !important;
    border: none;
    width: 36px;
    height: 36px;
    margin: 0 0 0 16px;
    padding: 0;
}

.progress-wrap .delete-btn img {
    width: 100%;
    height: 100%;
}

.progress-wrap .progress-value {
    display: inline-block;
    font-size: 14px;
    color: #273894;
}

.Toastify__toast Toastify__toast-theme--colored {
    border-radius: 20px;
}

.Toastify__toast--success {
    background: linear-gradient(0deg, #D7EAD9, #D7EAD9),
        linear-gradient(0deg, #EBF4EC, #EBF4EC) !important;
    border: 1px solid #D7EAD9 !important;
    font-weight: 700;
    font-size: 14px;
}

.Toastify__toast--success,
.Toastify__toast--success .Toastify__close-button {
    color: #2D7738 !important;
}

.Toastify__toast--error {
    color: #9E6767 !important;
    background: #F5E0E0 !important;
    border: 1px solid #E81212 !important;
}

.Toastify__toast--error .Toastify__close-button {
    color: #9E6767;
}

.Toastify__toast-container--top-center {
    width: auto !important;
}

table.alternate td,
table.alternate th {
    border-left: 1px solid #edf2f7 !important;
    border-right: 1px solid #edf2f7 !important;
}

table.alternate td:first-child,
table.alternate th:first-child {
    border-left: none !important
}

table.alternate td.fatal:last-child,
table.alternate th:last-child {
    border-left: none !important
}

table.alternate tr:nth-child(even) {
    background-color: #F9F9F9 !important
}

table.alternate td.fatal {
    background-color: #FDF6F6 !important;
    border: 1px solid #BC2F32 !important;
    border-left-color: #eee !important;
    border-right-color: #eee !important;
    background-clip: padding-box;
}

table td.td_status input[type="radio"] {
    -webkit-appearance: button;
    /* WebKit */
    -moz-appearance: button;
    /* Mozilla */
    -o-appearance: button;
    /* Opera */
    -ms-appearance: button;
    /* Internet Explorer */
    appearance: button;
    /* CSS3 */
}

.td_status [type="radio"] {
    opacity: 0;
}

.td_status [type="radio"] {
    position: absolute;
    z-index: -1;
}

.td_status [type="radio"]:checked+label.btn-outline-success {
    background-color: #107C10;
    color: white !important;
    border-color: #107C10 !important;
}
.td_status [type="radio"]+label.btn-outline-success:hover{
    color: #107C10;
}

.td_status [type="radio"]+label.btn-outline-danger:hover{
    color: #D92D20 !important;
}
.td_status [type="radio"]:checked+label.btn-outline-danger {
    background-color: #D92D20;
    color: white !important;
    border-color:#D92D20  !important;
}

.td_status [type="radio"]+label.btn-outline-secondary {
    border: 1px solid #98A2B3;
    /* color: #26378C; */

}
.td_status [type="radio"]+label.btn-outline-secondary:hover{
    color: #98A2B3;
}

.td_status [type="radio"]:checked+label.btn-outline-secondary {
    background-color: #98A2B3;
    color: white;
}

.td_status [type="radio"]+label.btn-outline-partially {
    border: 1px solid #817400;
    /* color: #FD853A; */

}
.td_status [type="radio"]+label.btn-outline-partially:hover{
    color: #FD853A;
}

.td_status [type="radio"]:checked+label.btn-outline-partially {
    background-color: #FD853A;
    color: white;
    border-color: #FD853A !important;
}
/* average */
.td_status [type="radio"]+label.btn-outline-average {
    border: 1px solid #817400;
    /* color: #FD853A; */

}
.td_status [type="radio"]+label.btn-outline-average:hover{
    color: #1DB056;
}

.td_status [type="radio"]:checked+label.btn-outline-average {
    background-color: #1DB056;
    color: white;
    border-color: #1DB056 !important;
}
/* Improvement */
.td_status [type="radio"]+label.btn-outline-improvement {
    border: 1px solid #817400;
    /* color: #FD853A; */

}
.td_status [type="radio"]+label.btn-outline-improvement:hover{
    color: #FDB022;
}

.td_status [type="radio"]:checked+label.btn-outline-improvement {
    background-color: #FDB022;
    color: white;
    border-color: #FDB022 !important;
}


.content-inner {
    display: grid;
    grid-template-columns: 80px 1fr;
    padding-left: 0px !important;
    gap: 16px;
    position: relative;
}

.content-inner aside {
    position: fixed;
    height: calc(100vh - 70px);
    top: 67px;
    overflow-y: auto;
}
.content-inner .scrollbar-thumb
{
    background-color: #d0d5dd;
    width: 5px;
}
.content-inner .scrollbar-track
{
    background-color: transparent;
    width: 5px;
}
.sidebar.sidebar-mini
{
    --sidebar-width: 82px;
}
.content-inner .sidebar-list
{
    padding: 0 5px;
}
.content-inner aside::-webkit-scrollbar {
    width: 2px;
}

.content-inner aside::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.content-inner aside::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.page-wrapper {
    margin-top: 67px;
    max-height: calc(100vh - 67px);
    /* overflow-y: auto; */
}
.page-wrapper.horizental_scroll_fixed{
    max-height: 100% !important;
}

.nav_sec {
    position: fixed !important;
    width: 100%;
    top: 0;
    background: white;
    z-index: 999;
}

.nav_sec .nav {
    padding: 5px 0;
}

.nav-link.active span {
    color: #101828 !important;
}

.sidebar-footer {
    /* border-top: solid 1px #2e3138; */
    padding: 0 5px;
}

.sidebar-footer .nav-item {
    list-style: none;
}

a.nav-link:hover {
    color: var(--bs-primary) !important;
}

a.nav-link {
    padding: 12px 10px !important;
}

a.nav-item {
    position: relative;
}

.sidebar.sidebar-mini.sidebar-base:not(.sidebar-hover:hover) .nav-item .nav-link:not(.static-item) span {
    transform: none;
    opacity: 1;
    font-size: 10px;
    position: relative;
    left: -8px;
    width: 80px;
    text-align: center;
    white-space: normal;
    display: inline-block;
    /* top: 56px; */
    margin: 0;
    min-width: 63px;
    color: rgba(255, 255, 255, 0.7);
}

.sidebar-mini .sidebar-body {
    padding-right: 0px;
}

.nav-item {
    margin-bottom: 10px;
}

.sidebar-list .nav-item:last-child {
    margin-bottom: 20px;
}

.nav-link.active i.icon img,
.nav-link:hover i.icon img {
    /* filter: invert(43%) sepia(28%) saturate(2820%) hue-rotate(231deg) brightness(97%) contrast(94%); */
    
}
.nav-link.active i.icon img, .nav-link:active i.icon img
{
    filter: sepia(100%) saturate(10000%) grayscale(100%) brightness(0) contrast(100);
}

.tab_nu_count {
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 2px 9px;
    line-height: 1;
}

.tab_view .nav-item a {
    display: flex;
    flex-direction: row;
}

.rebutal_tab_body textarea {
    height: 64px;
}

.rebutal_tab_body .bottom-actions {
    margin-top: 16px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.marking {
    color: red;
    font-size: 12px;
    margin-top: 10px;
}

/*  */
.dash_graph_box {
    display: flex;
}

.dash_graph_box .dash_graph {
    width: 100%;
}

.dash_graph_box .dash_graph .card {
    height: 100%;
}

.dash_graph .card .card-body {
    padding: 16px 16px;
}

.graph_scroll_height {
    overflow-y: auto;
    max-height: 394px;
    margin-bottom: 0;
}

.work_alc_list {
    display: flex;
    justify-content: space-between;
    border: 1px solid #EEEEF2;
    padding: 8px 10px;
    border-radius: 4px;
    margin: 0 0 10px 0;
}

.work_alc_list .work_media {
    align-items: center;
}

.work_alc_list .work_media .work_img {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5E9FF;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.work_alc_list .work_media h3 {
    font-size: 14px;
    color: #484964;
    margin: 0;
    font-weight: 400;
}
.work_alc_list .work_media .work_name{
    text-align: left;
}
.work_alc_list .work_no
{
    min-width: 20px;
    text-align: right;
}
.work_alc_list h2 {
    font-size: 20px;
    color: #484964;
    margin: 0 0 0px 0;
}
.dash_graph a{
    color: #000;
}
/*  */

.react-dropdown-tree-select .tag-list {
    margin: 0px;
}

.react-dropdown-tree-select .tag-list .tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 254px; */
    width: 100%;
    background: none;
    border: none;
    color: #000;
    max-width: 110px;
    font-size: 14px;
    line-height: 1.4;
}

.react-dropdown-tree-select .node {
    padding-top: 0px;
    padding-bottom: 0px;
}

.react-dropdown-tree-select .node i {
    margin-right: 0px !important;
    padding-right: 14px !important;
    margin-left: 0px !important;
    padding-left: 14px !important;
    height: 37px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    width: 28px !important;
}

.react-dropdown-tree-select .node label {
    margin-left: 0px;
    padding: 8px 4px;
    flex-grow: 1;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 0px;
}

.react-dropdown-tree-select .node.disabled i,
.react-dropdown-tree-select .node.disabled label {
    background-color: #f2f2f2;
}

.react-dropdown-tree-select .node:not(.disabled) i {
    display: none;
}

.react-dropdown-tree-select .node.disabled {
    cursor: not-allowed;
}

.react-dropdown-tree-select .node.checked label {
    cursor: not-allowed;
}

.react-dropdown-tree-select .node:not(.disabled):hover label,
.react-dropdown-tree-select .node.checked label {
    background-color: #f2f2f2;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.react-dropdown-tree-select .tag-list .tag:hover::after {
    content: attr(aria-label);
    position: absolute;
    left: 0px;
    background-color: rgba(0, 0, 0, 8);
    color: white;
    border-radius: 4px;
    width: 280px;
    text-wrap: wrap;
    padding: 8px;
    z-index: 99999;
    bottom: -38px;
    left: 0;
    white-space: break-spaces;
    font-size: 12px;
}

.tag-remove {
    position: absolute;
    right: 47px;
    top: 12px;
}

.react-dropdown-tree-select .dropdown-trigger {
    min-height: 40px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.qa_dash_right_cardbody {
    .apexcharts-tooltip {
        display: none !important;
    }

    .apexcharts-xcrosshairs {
        display: none !important;
    }
}
.react-dropdown-tree-select .disabled .tag-list .tag
{
    color: #BDBDBD;
    font-size: 14px;
}
@media screen and (max-width: 1200px) {
    .audio_player {
        padding: 0 0 10px;
    }

    .audio_player audio {
        width: 100%;
    }

    .audio_player>div {
        width: 100%;
    }
}

@media screen and (max-width: 1080px) {
    .audio_player {
        padding: 0 0 10px;
    }

    .audio_player audio {
        width: 100%;
    }

    .audio_player>div {
        width: 100%;
    }
}

.horizontal-bar-container .bar>div {
    position: relative;
}

.horizontal-bar-container .bar div {
    font-size: 10px;
    position: relative;
    top: 5px;
    overflow-x: visible;
    height: 15px;
}


.page-link {
    cursor: pointer;
}

.disabled {
    background-color: #e9ecef !important;
    color: #BDBDBD !important;
}

.drpdown_tree_select .node.focused.disabled {
    background-color: #e9ecef !important;
    color: #BDBDBD !important;
}

.audiourl {
    display: grid;
    gap: 10px;
    grid-template-columns: 36px 1fr;
    max-width: 100%;
    width: 100%;
}

.audiourl div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    display: flex;
    align-items: center;
}

.table_style_comman td {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.topMenuImport {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.audit_acceptance .apexcharts-tooltip-y-group {
    display: flex !important;
    flex-direction: column !important;
    justify-content: left !important;
    color: #4F4F4F !important;
}

.audit_acceptance .apexcharts-tooltip-series-group {
    background-color: white !important;
    width: 100px;
    height: 40px;
    top: 582.59px;
    left: 936px;
    border-radius: 2px;
}

.audit_acceptance .apexcharts-tooltip-text .apexcharts-tooltip-y-group:first-child span:first-child {
    font-size: 10px !important;
    font-weight: 200 !important;
    font: 'Segoe UI' !important;
    line-height: 13.3px !important;
    width: 37px !important;
    height: 13px !important;
}

.audit_acceptance .apexcharts-tooltip-text .apexcharts-tooltip-y-group:first-child span:last-child {
    font-size: 15px !important;
    font-weight: 540 !important;
    font: 'Segoe UI' !important;
    line-height: 15.96px !important;
    text-align: left !important;
    margin-left: 0px;
    width: 65px !important;
    height: 16px !important;
    top: 597.59px !important;
    left: 941px !important;
}

.timeDropDownAccordian .accordion-item {
    position: relative;
    border-radius: 4px;
    border-color: var(--bs-border-color) !important;
}

.timeDropDownAccordian .accordion-collapse {
    position: absolute;
    background-color: #FFF;
    width: 100%;
}

.cus_all_datatype .was-validated .form-select,
.cus_all_datatype .form-select {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;
    background-repeat: no-repeat !important;
    background-position: right 13px center;
    background-size: 16px 12px;
}

.audit_create_padding_btm {
    padding-bottom: 60px;
}

.form_style_comman select {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;
    background-repeat: no-repeat !important;
    background-position: right 13px center;
    background-size: 16px 12px;
    appearance: none;
}
.form_style_comman select:disabled
{
    color: #BDBDBD !important;
}
.refresh_data {
    color: #484964;
    font-size: 14px;
    font-weight: 500;
    cursor: default;
}

.refresh_data i {
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
    padding: 3px;
    border-radius: 4px;
}

.flatpickr-current-month {
    font-size: 100%;
}

.flatpickr-innerContainer {
    font-size: 12px;
}

/* All view of obs*/
.allView .modal-dialog {
    max-width: 1000px;
}

.allView .tag-remove {
    position: relative;
    right: auto;
    top: auto;
}

.allView .react-dropdown-tree-select .tag-list .tag {
    width: auto;
    margin-right: 10px;
    font-size: 12px;
    max-width: max-content;
}

.allView .react-dropdown-tree-select .dropdown {
    display: block;
    width: 430px;
    max-width: 100%;
}

.allView .tooltipobs {
    position: absolute;
    background: #000;
    color: #fff;
    z-index: 9;
    padding: 7px 10px;
    border-radius: 4px;
    top: 43px;
    left: 0;
    display: none;
}

.allView .react-dropdown-tree-select .tag-list .tag:hover .tooltipobs {
    display: block;
}

.allView .react-dropdown-tree-select .tag-list .tag:hover::after {
    display: none;
}

.allView .react-dropdown-tree-select .dropdown ::-webkit-scrollbar {
    height: 6px;
}

.allView .react-dropdown-tree-select .dropdown ::-webkit-scrollbar-thumb:hover {
    background: var(--bs-primary);
}

.allView .drpdown_tree_select .tag-item:not(:first-child) {
    display: block;
}

.btn_transparent {
    background-color: transparent !important;
    border: none;
    padding: 0 2px;
}
.btn_transparent.btn.btn-primary:hover,
.btn_transparent.btn.btn-primary:focus
{
    background-color: transparent !important;
}

.form_wizard_area .action-button {
    font-size: 14px;
    padding: 7px 16px;
}

.timer_display .sd-timer {
    display: none !important;
}

.spinner-button {
    width: 14px;
    height: 14px;
    border-width: 1px;
    margin-right: 4px;
}

.audit-save-disabled {
    padding: 8px 18px;
}

/*Loader in OBS css Start*/
.obs_loader .drpdown_tree_select .dropdown-content {
    padding: 0 !important;
}

.obs_loader .drpdown_tree_select .dropdown-content .infinite-scroll-component:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;

}

.obs_loader .drpdown_tree_select .dropdown-content .infinite-scroll-component:after {
    content: "\f1ce";
    font-family: 'FontAwesome';
    position: absolute;
    left: 45%;
    top: 25%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 20px;
    line-height: 1;
    color: white;
    border-radius: 50%;
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.obs_loader .infinite-scroll-component {
    position: relative;
}

@keyframes rotate {
    from {
        transform: rotate(-360deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.diy_Dept_Name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
}
/*Loader in OBS css Ends*/

/*Audit sheet list start*/
.uploadaudiospinner .spinner-border {
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
    margin-top: -10px;
    display: block;
    margin-left: 8px;
}
 
.uploadaudio{
    margin-top: 5px;
    margin-left: 8px;
}
/*Audit sheet list end*/
.perform_audit_wrapper{
    overflow-y: inherit !important;
}
.parameter_inp_bx
{
    font-size: 14px;
}

.was-validated .drpdown_tree_select:has(#obsParam_trigger) .dropdown-trigger.arrow {
    border: solid 1px #D13438 !important;
}

.was-validated :has(#obsParam_trigger) #obsError {
    display: block !important;
    border: none !important
 }
 .metricsStatus p {
    font-weight: 500;
    font-size: 14px;
 }

.loader_blur_area
{
    position: relative;
}
.loader_comp
{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 9;
}
.loader_comp .text-light
{
    color: #fff !important;
}
.loader_blur_area:before{
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
}
 /*  */
 .permission_card
 {
    position: relative;
    padding-right: 20px;
 }
 .permission_card .permission_card_media
 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
 }
 .permission_card .permission_close
 {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 5px;
    cursor: pointer;
 }
 .deesignation_modal .modal-dialog {
    max-width: 400px;
}
/* settings page */
.setting_card_box
{
    display: flex;
}
.setting_card{
    padding: 15px;
    width: 100%;
}
.setting_card .setting_card_icon
{
    border-color: #D0D5DD;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.setting_card .setting_card_name
{
    font-weight: 600;
    font-size: 16px;
}
.setting_card .setting_card_more a
{
    padding: 6px 4px;
}
.setting_card .setting_card_body
{
    margin-top: 15px;
    color: var(--bs-dark-grey);
    font-size: 14px;
}

.card_legends_box
{

}
.card_legends_box .card_legends
{
    padding: 15px;
}
.card_legends_box .card_legends h6{
    font-size: 16px;
    color: var(--color_subtitle);
}
.card_legends_box .card_legends p{
    font-size: 14px;
    color: #98A2B3;
}
.card_legends_box .card_legends h4{
    font-size: 20px;
    color: #1D2939;
}
/*  */
.percentage-progress h6
{
    font-size: 16px;
    color: #344054;
}
.percentage-progress .progress_nu
{
    color: #98A2B3;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}
.edit_plan_modal .modal-dialog {
    max-width: 760px;
}
.form_output
{
    margin: 0 0 5px 0;
    font-weight: 500;
}
.client_box_repeat
{
    border-bottom: 1px solid #EAECF0;
}
.client_box_repeat:last-child
{
    border-bottom: none;
}
.fileupload_output
{
    border: 1px solid #EAECF0;
    padding: 10px 10px;
    border-radius: 4px;
}
.agreement_view p
{
    border: 1px solid var(--bs-border-color) !important;
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 14px;
    color: #101828;
    overflow: hidden;
    height: 39px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: inherit;
    word-break: break-all;

    text-overflow: ellipsis;
    white-space: nowrap !important;
    display: inline-flex;
    align-items: center;
}
.agreement_view p span{
    line-height: 1;
}
/* .custom_country_phone .PhoneInput
{
    border: 1px solid var(--bs-border-color);
    height: 39px;
    padding:  0 10px;
    border-radius: 4px;
}
.custom_country_phone .PhoneInput .PhoneInputCountry
{

}
.custom_country_phone .PhoneInput .PhoneInputCountry .PhoneInputCountrySelect
{
    padding: 10px;
}
.custom_country_phone .PhoneInput .PhoneInputCountry .PhoneInputCountryIconUnicode
{
    font-size: 16px;
    color: #667085;
}
.custom_country_phone .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow
{
    font-size: 22px;
}
.custom_country_phone .PhoneInput .PhoneInputInput
{
    border: none;
}
.custom_country_phone .PhoneInput.PhoneInput--focus{
    border-color: var(--bs-primary);
} */
/* custom file upload */
.custom_fileupload .file-upload-wrapper {
    position: relative;
    width: 100%;
    height: 39px;
    border: 1px solid var(--bs-border-color);
    border-radius: 4px;
}
.custom_fileupload.custom_fileupload .file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    padding: 0px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 36px;
    line-height: 40px;
    color: #999;
    /* border-radius: 5px 10px 10px 5px; */
  }
.custom_fileupload .file-upload-wrapper:before {
    content: "Upload Agreement";
    position: absolute;
    top: 0;
    right: 0px;
    display: inline-block;
    height: 100%;
    background: transparent;
    color: var(--bs-primary);
    z-index: 25;
    font-size: 14px;
    line-height: 1;
    padding: 12px 15px;
    pointer-events: none;
    border-radius: 4px;
    border: 1px solid var(--bs-primary);
    letter-spacing: 0.1px;
    font-weight: 600;
  }
.custom_fileupload.custom_fileupload .file-upload-wrapper:hover:before {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
    background-color: var(--bs-brand_light_green) !important;
  }
.custom_fileupload .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .custom_fileupload .file-upload-wrapper.disabled:before,
  .custom_fileupload .file-upload-wrapper.disabled:hover:before {
    background-color: #F0F0F0 !important;
    color: #d3d3d3;
    border: 1px solid #E0E0E0;
}